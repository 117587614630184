import { lazy } from "react";

// import AdminPanel from "./admin/Admin";
// import Login from "./auth/Login";

const Home = lazy(() => import("./homepage/Home"));

const About = lazy(() => import("./program/About"));
const Beneficiaries = lazy(() => import("./program/Beneficiaries"));
const StrategyPage = lazy(() => import("./program/StrategyPage"));

const TargetReports = lazy(() => import("./reports/target/Target"));

const Denominators = lazy(() =>
  import("./reports/raw-data/denominators/Denominators")
);
const Numerator = lazy(() => import("./reports/raw-data/numerator/Numerator"));
const DrugRequirement = lazy(() =>
  import("./reports/raw-data/drug-requirement/DrugRequirement")
);

const KPI = lazy(() => import("./reports/KPI"));
const IFAStock = lazy(() => import("./stock/IFAStock"));
const AMBRanking = lazy(() => import("./ranking/AMBRanking"));

const ResourcePage = lazy(() => import("./resources/ResourcePage"));

const ContactUs = lazy(() => import("./connect/ContactUs"));

const ErrorPage = lazy(() => import("./errorPage/errorPage.js"));

const AdminPanel = lazy(() => import("./admin/Admin"));
const Login = lazy(() => import("./auth/Login"));
const ResourceAdmin = lazy(() => import("./admin/ResourceAdmin"));
const DrugRequirements = lazy(() => import("./admin/UploadDrugRequirements"));
const UploadDenominators = lazy(() => import("./admin/UploadDenominators"));
const UploadNumerators = lazy(() => import("./admin/UploadNumerators"));
const DeleteExcelUploads = lazy(() => import("./admin/DeleteExcelUploads"));
const CountAdmin = lazy(() => import("./admin/CountAdminPage"));

const Register = lazy(() => import("./auth/RegistrationProcess"));
const ForgotPassword = lazy(() => import("./auth/ForgotPasswordProcess"));
const ChecklistBeneficiary = lazy(() =>
  import("./checklist/ChecklistBeneficiary")
);
const ChecklistDistrict = lazy(() => import("./checklist/ChecklistDistrict"));
const SchoolForm = lazy(() => import("./checklist/SchoolForm"));

export {
  About,
  AdminPanel,
  AMBRanking,
  Beneficiaries,
  ChecklistBeneficiary,
  ChecklistDistrict,
  ContactUs,
  CountAdmin,
  DeleteExcelUploads,
  Denominators,
  DrugRequirement,
  DrugRequirements,
  ErrorPage,
  ForgotPassword,
  Home,
  IFAStock,
  KPI,
  Login,
  Numerator,
  Register,
  ResourceAdmin,
  ResourcePage,
  SchoolForm,
  StrategyPage,
  TargetReports,
  UploadDenominators,
  UploadNumerators,
};
