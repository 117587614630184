import { useEffect, useRef } from "react";
import "./ticker.css";

const Ticker = ({ text, speed = 50 }) => {
  const tickerRef = useRef(null);

  useEffect(() => {
    const ticker = tickerRef.current;

    const startScroll = () => {
      if (ticker) {
        const width = ticker.scrollWidth;
        let currentPosition = 0;

        const scroll = () => {
          currentPosition -= 1; // Adjust for scroll speed
          if (Math.abs(currentPosition) >= width) {
            currentPosition = ticker.offsetWidth;
          }
          ticker.style.transform = `translateX(${currentPosition}px)`;
          requestAnimationFrame(scroll);
        };

        scroll();
      }
    };

    startScroll();
  }, [text]);

  return (
    <div className="ticker-container">
      <div className="ticker" ref={tickerRef}>
        {text}
      </div>
    </div>
  );
};

export default Ticker;
