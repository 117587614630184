import React from "react";
import AMBLogo from "assets/img/LogoAmb1.png";

const CustomLoader = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light">
      <img
        src={AMBLogo}
        alt="Loading..."
        className="img-fluid mb-3"
        style={{ width: "70px", maxWidth: "100%" }}
      />
      {/* <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div> */}
      <p className="mt-3 text-secondary fs-5">Loading...</p>
    </div>
  );
};

export default CustomLoader;
